import axios from 'axios';
import { initializeAxiosMockAdapter, isMockEnabled } from '../mock/mock.config';

export const APICurrency = axios.create({
  baseURL: process.env.GATSBY_CURRENCYBIRD_CURRENCIES_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const APIQuote = axios.create({
  baseURL: process.env.GATSBY_CURRENCYBIRD_QUOTE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

if (isMockEnabled()) {
  initializeAxiosMockAdapter(APICurrency);
}
