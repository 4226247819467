import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ImportantDevicesRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiIconButton-root': {
        color: '#FFFFFF',
      },
      '.MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '.MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    optionAutoComplete: {},
    inputRoot: {
      height: 80,
      paddingBottom: 19,
    },
    flags: {
      '& img': {
        display: 'inline-block',
        width: '2em !important',
        height: '2em !important',
        verticalAlign: 'middle',
        [theme.breakpoints.down(501)]: {
          width: '1.8em !important',
          height: '1.8em !important',
        },
      },
    },
    option: {
      fontSize: 20,
      '& > span': {
        marginRight: 10,
        fontSize: 28,
      },
      [theme.breakpoints.down(1280)]: {
        fontSize: 24,
      },
      [theme.breakpoints.down(768)]: {
        fontSize: 28,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
      },
    },
    inputCurrency: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      //padding: '0 20%',
      padding: 0,
      color: '#FFFFFF',
      [theme.breakpoints.down(1364)]: {
        fontSize: 16,
        // padding: '0 10%',
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 12,
        // padding: '0 0%',
      },
    },
    popper: {
      width: 300,
    },
  })
);

export default useStyles;
