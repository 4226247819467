import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICurrencyData } from '../../types';
import useStyles from './Currency.style';
import ReactCountryFlag from 'react-country-flag';
import { Grid, Popper, Box, TextField } from '@material-ui/core';

const PopperMy = function (props: any) {
  return <Popper {...props} style={{ width: 300 }} placement="bottom-start" />;
};

const Currency = (props: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<ICurrencyData | null>(null);

  function normalizeWord(word: string) {
    return word
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  function setCurrencyDefault() {
    if (props && props.currencies) {
      if (props.currencies.length > 0) {
        setValue(props.currencies[0]);
        props.setCurrency(props.currencies[0]);
        for (const currency of props.currencies) {
          if (currency.isoCode === 'US' && currency.currency === 'USD') {
            setValue(currency);
            props.setCurrency(currency);
          }
        }
      }
    }
  }
  useEffect(() => {
    setCurrencyDefault();
  }, [props.currencies]);

  return (
    <Grid container spacing={0}>
      <Grid item md={5} xs={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.flags}
          sx={{ bgcolor: '#296B88', height: '80px' }}
        >
          {value ? <ReactCountryFlag countryCode={value.isoCode} svg /> : <></>}
        </Box>
      </Grid>
      <Grid item md={7} xs={8}>
        <Autocomplete
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={value}
          style={{ height: 85 }}
          onChange={(event, newValue) => {
            setValue(newValue);
            props.setCurrency(newValue);
            props.currencyOnChange(props.quoteType, newValue);
          }}
          filterOptions={(options, state) => {
            const filterResult: ICurrencyData[] = [];
            const { inputValue } = state;
            if (!inputValue) {
              return options;
            }
            options.forEach((item) => {
              if (
                normalizeWord(item.currency).includes(normalizeWord(inputValue)) ||
                normalizeWord(item.countryName).includes(normalizeWord(inputValue))
              ) {
                filterResult.push(item);
              }
            });
            return filterResult;
          }}
          options={props.currencies as ICurrencyData[]}
          classes={{
            option: classes.option,
            input: classes.optionAutoComplete,
            inputRoot: classes.inputRoot,
          }}
          autoHighlight
          selectOnFocus
          clearOnBlur
          onOpen={(event) => {
            setValue(null);
            props.setCurrency(null);
          }}
          onClose={(event: any, reason: string) => {
            if (value === null && reason === 'blur') {
              setCurrencyDefault();
            }
          }}
          disableClearable
          clearOnEscape
          handleHomeEndKeys
          PopperComponent={PopperMy}
          getOptionLabel={(option) => option.currency}
          getOptionSelected={(option, value) => {
            return option.currency === value.currency;
          }}
          renderOption={(option) => (
            <React.Fragment>
              <span>
                <ReactCountryFlag countryCode={option.isoCode} svg />
              </span>
              <Box fontWeight="fontWeightBold">{option.currency}</Box>
              <Box m={0.5} fontWeight="fontWeightLight">
                -
              </Box>
              <Box fontWeight="fontWeightLight">{option.countryName}</Box>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="standard"
              style={{ backgroundColor: '#296B88', border: '0px', height: '80px' }}
              inputProps={{
                ...params.inputProps,
                disableUnderline: true,
                className: classes.inputCurrency,
                autoComplete: '', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Currency;
